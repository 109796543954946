import { render, staticRenderFns } from "./MegaMenuSearch.vue?vue&type=template&id=f5074ace&scoped=true&"
import script from "./MegaMenuSearch.vue?vue&type=script&lang=js&"
export * from "./MegaMenuSearch.vue?vue&type=script&lang=js&"
import style0 from "./MegaMenuSearch.vue?vue&type=style&index=0&id=f5074ace&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5074ace",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default,MegaMenuSearchResults: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/MegaMenu/Search/MegaMenuSearchResults.vue').default,BaseFormInput: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/form/BaseFormInput.vue').default,BaseButton: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseButton.vue').default})
