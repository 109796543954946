import { render, staticRenderFns } from "./HeaderCart.vue?vue&type=template&id=8e52e3e2&"
import script from "./HeaderCart.vue?vue&type=script&lang=js&"
export * from "./HeaderCart.vue?vue&type=script&lang=js&"
import style0 from "./HeaderCart.vue?vue&type=style&index=0&id=8e52e3e2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseIcon.vue').default,BaseBadge: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseBadge.vue').default,HeaderButton: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Header/HeaderButton.vue').default,HeaderMinicartItems: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Header/Minicart/HeaderMinicartItems.vue').default,PriceField: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Price/PriceField.vue').default,Price: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Price/Price.vue').default,BaseButton: require('/home/gitlab-runner/builds/3h9sKVRF/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseButton.vue').default})
